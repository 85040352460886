#topbar{
	background-color: #013565;
	font-size: 14px;
	letter-spacing: 0.4px;
	color: #ffffff;
	padding-top: 7px;
	padding-bottom: 7px;

	ul{
		@extend .list-inline;
		text-align: center;
		margin-bottom: 0;

		li{
			@extend .list-inline-item;
			@include media-breakpoint-only(xs) { 
				display: block;
			}
			

			&.mail{
				// font-weight: 900;
				img{
					width: 15px;
					height: 11.2px;
					object-fit: contain;
				}
			}

			&.phone{
				// font-weight: 900;

				img{
					width: 15px;
					height: 15px;
					object-fit: contain;
				}
			}
		}
	}
}

#navbar{
	box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.23);

	.logo {
		width: 261px;
		height: 61px;
		object-fit: contain;

		@include media-breakpoint-only(xs) { 
			max-width: 180px;
		}

	}
}

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active{
	font-weight: 900;
	text-decoration: underline;;
}


#footer{
	background-color: #4a4a4a;
	padding-top: 46px;
	padding-bottom: 46px;
	top: -5px;
	position: relative;

	.logo-blanco {
		width: 193px;
		height: 45px;
		object-fit: contain;
		@include media-breakpoint-only(xs) { 
			margin-bottom: 10px;
		}
	}

	.logo-small{
		width: 89px;
		height: 21px;
	    // height: 15px;
	    object-fit: contain;
	}

	.site-map{
		li{
			font-size: 14px;
			font-weight: 500;
			font-style: normal;
			font-stretch: normal;
			line-height: normal;
			letter-spacing: 0.4px;
			a{
				color: #ffffff !important;
			}
		}
	}

	h6, p{
		font-size: 14px;
		font-weight: bold;
		font-style: normal;
		font-stretch: normal;
		line-height: normal;
		letter-spacing: 0.5px;
		color: #ffffff;
	}

	.aviso-de-privacidad {
		font-size: 10px;
		font-weight: 300;
		font-style: normal;
		font-stretch: normal;
		line-height: normal;
		letter-spacing: normal;
		color: #ffffff;
		text-decoration: underline;
		display: block;
	}

	.todos-los-derechos {
		font-size: 10px;
		font-weight: 300;
		font-style: normal;
		font-stretch: normal;
		line-height: normal;
		letter-spacing: normal;
		color: #ffffff;
		display: block;
	}
}
