
@import 'bootstrap';
@import 'layout';
@import 'index';
@import 'node_modules/ekko-lightbox/dist/ekko-lightbox';

body {
  padding-top: 122px;
  background-color: #4a4a4a;
}

body, #content {
  position: relative;
}

