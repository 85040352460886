.carousel-caption{

	@include media-breakpoint-only(sm) {
		bottom: 18%;
	}

	@include media-breakpoint-only(md) {
		bottom: 18%;

	}
	@include media-breakpoint-only(lg) {
		bottom: 31%;

	}
	@include media-breakpoint-only(xl) {
		bottom: 43%;

	}


	h5{
		font-size: 55px;
		font-weight: 900;
		font-style: italic;
		letter-spacing: 1.2px;
		text-align: center;
		color: #ffffff;
		text-shadow: 0 2px 2px rgba(0, 0, 0, 0.5);

		@include media-breakpoint-only(xs) {
			font-size: 20px;
		}

		@include media-breakpoint-only(sm) {
			font-size: 36px;
		}
	}

}

#nosotros, #servicios{
	background-color: #ffffff;
}

#empresa{
	padding-top: 72px;
	padding-bottom: 75px;

	p{
		font-size: 20px;
		font-weight: 600;
		letter-spacing: 0.5px;
		text-align: left;
		color: #4a4a4a;
	}
}

#especialistas{
	background-color: #013565;
	padding-top: 51px;
	padding-bottom: 51px;


	h4{
		font-family: Lato;
		font-size: 24px;
		font-weight: 900;
		letter-spacing: 0.6px;
		text-align: center;
		color: #ffffff;
		margin-bottom: 36px;
	}

	ul{
		margin-bottom: 0 !important;
	}

	li{
		font-size: 14px;
		letter-spacing: 0.3px;
		color: #ffffff;
	}
}

#slider-productos{

	.carousel-control-prev,
	.carousel-control-next{
		width: auto;
		opacity: 1;
	}

	.carousel-control-prev {
		left: -35px;
		@include media-breakpoint-only(xs) { 
			left: 20px;
		}
	}

	.carousel-control-next {
		right: -35px;
		@include media-breakpoint-only(xs) { 
			right: 20px;
		}
	}


	.slide-by-four{
		text-align: center;


		@include media-breakpoint-only(md) { 
			min-height: 349.50px;
		}

		@include media-breakpoint-only(lg) { 
			min-height: 464.75px;

		}

		@include media-breakpoint-only(xl) { 
			min-height: 560px;
		}


		
		a{
			display: inline-block;
			position: relative;
			margin: 15px;

			// limit 1200

			@include media-breakpoint-only(xs) { 
				max-width: 360px;
				margin: 5px;

			}

			@include media-breakpoint-only(sm) { 
				max-width: 240px;
				margin: 7px;

			}
			@include media-breakpoint-only(md) { 
				margin: 10px;
				max-width: 325px;
			}

			@include media-breakpoint-only(lg) { 
				max-width: 425px;
				margin: 15px;
			}

			span{

				position: absolute;
				bottom: 20px;
				left: 20px;
			    /* width: 100%; */
			    text-align: left;
			    color: #fff;

			}
		}
	}
}

#slider-productos-sm{
	.carousel-item{
		a{
			display: inline-block;
			position: relative;
			span{

				position: absolute;
				bottom: 20px;
				left: 20px;
			    /* width: 100%; */
			    text-align: left;
			    color: #fff;

			}
		}
	}
}

#productos{
	background-color: #f8f8f8;
	padding-top: 55px;
	padding-bottom: 51px;

}

#iconografia{
	background-color: #f8f8f8;
	padding-top: 53px;
	padding-bottom: 101px;



	h4{
		max-width: 554px;
		font-size: 24px;
		font-weight: 900;
		letter-spacing: 0.6px;
		text-align: center;
		color: #4a4a4a;
		text-transform: uppercase;
		margin-bottom: 68px;
	}

	img{
		display: block;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 15px;


		&.silla {
			width: 38.2px;
			height: 60px;
			object-fit: contain;
		}
		&.escuela {
			width: 47.2px;
			height: 60px;
			object-fit: contain;
		}
		&.locker{
			width: 45px;
			height: 60px;
			object-fit: contain;
		}
	}

	span{
		font-size: 18px;
		font-weight: bold;
		letter-spacing: 0.5px;
		text-align: center;
		color: #013565;
		max-width: 165px;
		display: block;
		margin-left: auto;
		margin-right: auto;
		@include media-breakpoint-down(sm) { 
			margin-bottom: 34px;
		}

	}
}


#alianzas{

	padding-top: 100px;
	padding-bottom: 100px;

	h4{
		font-size: 24px;
		font-weight: 900;
		letter-spacing: 0.6px;
		text-align: center;
		color: #013565;
		
	}

	p{
		font-size: 20px;
		font-weight: 600;
		letter-spacing: 0.5px;
		text-align: center;
		color: #4a4a4a;

		margin-bottom: 40px;
	}

}

#map{
	margin-top: 85px;
	height: 350px;
	width: 100%;
	p.gm-style-pbt{
		text-align: center;
	}
}

#contacto{
	padding-top: 60px;
	background-color: #013565;
	background-image: url('/images/patron.png');


	h4{
		font-size: 24px;
		font-weight: 900;
		letter-spacing: 0.6px;
		text-align: center;
		color: #ffffff;
		padding-bottom: 43px;
	}

	p.info{
		font-size: 20px;
		font-weight: 600;
		letter-spacing: 0.5px;
		text-align: center;
		color: #ffffff;
		padding-bottom: 40px;

	}

	p, h6{
		font-size: 18px;
		font-weight: 500;
		font-style: normal;
		font-stretch: normal;
		line-height: normal;
		letter-spacing: 0.6px;
		text-align: left;
		color: #ffffff;
	}

	.clock, .phone, .address, .email {
		position: relative;
		&:before{
			content: '';
			position: absolute;
			top: -25px;
			left: -25px;
			background-repeat: no-repeat;
			background-size: contain;
		}
	}

	.clock:before{
		width: 15px;
		height: 15px;
		background-image: url("/images/reloj.svg");
	}
	.phone:before{
		width: 18px;
		height: 18px;
		background-image: url("/images/icono-tel-fono.svg");

	}
	.address:before{
		width: 18px;
		height: 20px;
		background-image: url("/images/spot.svg");

	}
	.email:before{
		width: 19.7px;
		height: 15px;
		background-image: url("/images/mail.svg");

	}

	h6 {
		font-weight: bold;
	}

	.logo-medium{
		width: 143px;
		height: 24px;
		object-fit: contain;
	}

	.form-control{
		border-radius: 0px;
	}

	.btn{
		@include button-variant(#8a8a8a, #8a8a8a);
		border-radius: 20px;
		padding: 3px 65px;

	}

}
