
@import url('https://fonts.googleapis.com/css?family=Lato');
@import url('https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css');


$navbar-light-color: #4a4a4a;
$navbar-light-hover-color:          #013565;
$navbar-light-active-color:         #013565;
$navbar-nav-link-padding-x:         20px;
$font-family-sans-serif: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";

@import 'node_modules/bootstrap/scss/bootstrap.scss';
@import 'node_modules/bootstrap-block-grid/dist/bootstrap4-block-grid.min';
